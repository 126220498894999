import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import clsx from 'clsx'
import { Page, itemList, values } from '@karakuri-faq/component'

import IndexLayout from '../layouts'

import { SEOWidget as SEO } from '../widgets/SEOWidget'
import { TagData } from '../widgets/interface'
import { renderWidgets } from '../widgets/TagWidget'
import { LayoutType } from '../graphql.d'

interface TagPageProps {
  data: TagData
  pathContext: any
  location: any
}
const TagPage: React.FC<TagPageProps> = ({ data, pathContext, location }) => {
  const { url, tagCloud } = data.settingYaml
  const breadcrumb = {
    current: pathContext.title || pathContext.parentTitle,
    url: url || '',
    hierarchies: pathContext.parentTitle
      ? [
          {
            title: pathContext.parentTitle,
            link: pathContext.parentHasAnswers ? `/category/${pathContext.parentId}` : `/#${pathContext.parentId}`
          }
        ]
      : []
  }
  const { allAnswerYaml } = data
  const widgetProps = { data, breadcrumb, pathContext, tagCloud }
  const left = values(data.settingYaml.layoutLeft, [])
  const right = values(data.settingYaml.layoutRight, [])
  return (
    <IndexLayout>
      <SEO pathname={location.pathname} title={pathContext.title || pathContext.parentTitle} />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(itemList(allAnswerYaml))}</script>
      </Helmet>
      <Page className="faq-content faq-category-page">
        <div className="faq-main-content">
          <div className="faq-header">
            <h2>{pathContext.title}</h2>
          </div>
          {renderWidgets(
            [
              { id: 'navi', type: LayoutType.Navi },
              { id: 'tag', type: LayoutType.Tag }
            ],
            widgetProps
          )}
        </div>
        <div className={clsx('faq-left-content', { 'faq-no-content': left.length === 0 })}>{renderWidgets(left, widgetProps)}</div>
        <div className={clsx('faq-left-content', { 'faq-no-content': right.length === 0 })}>{renderWidgets(right, widgetProps)}</div>
      </Page>
    </IndexLayout>
  )
}
// const TagPage = ({ data, pathContext }: TagPageProps) => {}
export default TagPage

export const query = graphql`
  query TagQuery($id: String, $taggedAnswers: [String]) {
    allAnswerYaml(filter: { id: { in: $taggedAnswers }, ignore: { ne: true } }) {
      totalCount
      edges {
        node {
          id
          ask
        }
      }
    }
    tagYaml(relations: { in: [$id] }, ignore: { ne: true }) {
      id
      relations
      answers
    }
    settingYaml(ignore: { ne: true }) {
      botId
      url
      tagCloud
      layoutLeft {
        id
        title
        type
        icons {
          id
          title
          reference
          image
        }
      }
      layoutRight {
        id
        title
        type
        icons {
          id
          title
          reference
          image
        }
      }
    }
    allCategoryYaml(filter: { ignore: { ne: true } }) {
      edges {
        node {
          id
          title
          sub {
            id
            title
          }
        }
      }
    }
  }
`
