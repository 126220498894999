import React from 'react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { List, ListItem, Link, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
interface Tag {
  link: string
  title: string
}
interface Props {
  tags: Tag[]
}
const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingBottom: 0
  },
  listItem: {
    flex: 1,
    display: 'inline-flex',
    maxWidth: 130,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: 3,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  link: {
    display: 'inline-block',
    // height: 28,
    // lineHeight: 28,
    // padding: '0 1em',
    maxWidth: 140,
    color: '#2774cc',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    transition: '.2s'
  }
}))
export const TagCloud = ({ tags }: Props) => {
  const classes = useStyles()
  return (
    <List className={classes.list}>
      {tags.map(tag => {
        return (
          <ListItem
            key={tag.link}
            className={classes.listItem}
            button
            component="li"
            onClick={() => {
              navigate(tag.link)
            }}
          >
            <Tooltip title={tag.title}>
              <Link
                component={GatsbyLink}
                to={tag.link}
                // activeClassName={activeClassName}
                // partiallyActive={partiallyActive}
                color="textSecondary"
                underline="none"
                className={classes.link}
              >
                {tag.title}
              </Link>
            </Tooltip>
          </ListItem>
        )
      })}
    </List>
  )
}
