import React from 'react'
import { navigate } from 'gatsby'
import { useApolloClient } from 'react-apollo'

import { Breadcrumb, Search } from '@karakuri-faq/component'

import { search } from '../utils/gtm'
import { NaviWidgetProps } from './interface'
import { SEARCH_FAQ } from './graphql'
import { TagCloud } from './TagCloud'

export const NaviWidget: React.FC<NaviWidgetProps> = ({ botId, breadcrumb, tagCloud, relatedCategories }) => {
  const client = useApolloClient()
  // console.log('navi', navi, relatedCategories)
  const r = relatedCategories || []
  const tags = tagCloud
    ? [
        { link: '/', title: 'よくあるご質問' },
        ...r.map(c => {
          // console.log('aaa', c.title, c.sub && c.sub.length > 0, c.sub, c)
          const title = c.title || ''
          if (c.sub && c.sub.length > 0) {
            return { link: `/#${c.id}`, title }
          }
          return { link: `/category/${c.id}`, title }
        })
      ]
    : []
  return (
    <div className="faq-navigation">
      {tagCloud ? <TagCloud tags={tags} /> : <Breadcrumb {...breadcrumb} onLinkClick={navigate} />}

      <Search
        onClick={navigate}
        onSearched={search}
        search={inputValue => client.query({ query: SEARCH_FAQ, variables: { botId, inputValue } })}
      />
    </div>
  )
}
