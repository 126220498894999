import React from 'react'
import { navigate } from 'gatsby'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'

import { Button, CardActions } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { QAHeader, QAPanel, QAContent, value } from '@karakuri-faq/component'

import { CategoryWidget } from './CategoryWidget'
import { TagWidgetProps, LayoutProps } from './interface'
import { NaviWidget } from './NaviWidget'
import { LayoutType } from '../graphql.d'

const CATEGORY_FAQ = gql`
  query faqByCategory($botId: ID!, $categoryId: ID!) {
    getFAQByCategory(botId: $botId, categoryId: $categoryId) {
      id
      title
    }
  }
`

export const TagWidget: React.FC<TagWidgetProps> = ({ data, pathContext }) => {
  const { botId } = data.settingYaml
  const [page, setPage] = React.useState(1)
  const { loading, data: faqByCategory } = useQuery<{ getFAQByCategory: { id: string; title: string }[] }>(CATEGORY_FAQ, {
    variables: { botId, categoryId: pathContext.id }
  })
  const handleMoreClick = () => setPage(page + 1)
  const { allAnswerYaml } = data
  const totalCount = allAnswerYaml.totalCount || 0
  const perPage = 5

  const maxLoadingCount = page * perPage
  const hasNext = maxLoadingCount < totalCount

  if (loading || !faqByCategory || !faqByCategory.getFAQByCategory || faqByCategory.getFAQByCategory.length === 0) {
    return null
  }
  return (
    <QAPanel>
      <QAHeader
        title={`${totalCount}件中 1〜${maxLoadingCount < totalCount ? maxLoadingCount : totalCount}件`}
        titleClass="faq-pagination"
      />
      {faqByCategory.getFAQByCategory.map(({ title, id }, index) => {
        if (maxLoadingCount - 1 < index) {
          return null
        }
        const handleClick = () => {
          const categoryId = pathContext.parentId || pathContext.id
          const categoryName = pathContext.parentTitle || pathContext.title
          const subCategoryId = pathContext.parentId ? pathContext.id : null
          const subCategoryName = pathContext.parentTitle ? pathContext.title : null
          navigate(
            `/answer/${id}?category_id=${encodeURIComponent(categoryId)}&category_name=${encodeURIComponent(categoryName)}${
              subCategoryId ? `&sub_category_id=${encodeURIComponent(subCategoryId)}` : ''
            }${subCategoryName ? `&sub_category_name=${encodeURIComponent(subCategoryName)}` : ''}`
          )
        }
        return <QAContent key={id} question={title || ''} onClick={handleClick} />
      })}

      {hasNext && (
        <CardActions>
          <Button className="faq-button faq-button-more" variant="contained" color="secondary" fullWidth onClick={handleMoreClick}>
            もっと見る
            <ExpandMoreIcon className="faq-button-icon" />
          </Button>
        </CardActions>
      )}
    </QAPanel>
  )
}
export function renderWidgets(layouts: LayoutProps[], props: TagWidgetProps) {
  return layouts.map(layout => {
    switch (layout.type) {
      case 'navi':
        const relatedCategories = props.pathContext.parentId
          ? [{ id: props.pathContext.parentId, title: props.pathContext.parentTitle, sub: [{ id: props.pathContext.id }] }]
          : []
        return (
          <NaviWidget
            key={layout.id}
            botId={value(props.data.settingYaml.botId, '')}
            breadcrumb={props.breadcrumb}
            tagCloud={props.tagCloud}
            relatedCategories={relatedCategories}
          />
        )
      case 'tag':
        return <TagWidget key={layout.id} {...props} />
      case 'category':
        return (
          <CategoryWidget
            key={layout.id}
            layout={{ id: 'tag-category', type: LayoutType.Category }}
            data={props.data}
            pathContext={props.pathContext}
          />
        )
      default:
        return null
    }
  })
}
